<template>
  <content-layout :loading="loading" :breadcrumbs-items="breadcrumbsItems">
    <template #toolbar-actions>
      <div class="d-flex align-center" style="gap: 15px">
        <v-menu
          offset-y
          :nudge-bottom="10"
          :min-width="180"
          style="z-index: 160"
        >
          <template v-slot:activator="{ on, attrs }">
            <cz-button
              :title="$t('common.moreActions')"
              text
              large
              :icon-src="mdiDotsHorizontalCircleOutline"
              v-bind="attrs"
              v-on="on"
            /> </template
          >ֿ
          <v-list>
            <v-list-item
              v-clipboard="submissionLink"
              @click="onGrabLinkClicked"
            >
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiContentCopy"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('vendor.orderSubmissions.grabSubmissionLink')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onShowHistory">
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiHistory"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('customer.vendorSubmissions.submissionHistory')
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!loading && !saving && !sending && !readOnlyMode"
              @click="onCancelSubmission"
            >
              <v-list-item-icon class="ml-2"
                ><cz-icon :src="mdiCloseCircleOutline"
              /></v-list-item-icon>
              <v-list-item-title>{{
                $t('vendor.orders.cancelSubmission')
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y :min-width="800" style="z-index: 160">
          <template v-slot:activator="{ on, attrs }">
            <cz-button
              title="סטטוס הגשה"
              large
              text
              v-bind="attrs"
              v-on="on"
              :icon-src="mdiInformationOutline"
            />
          </template>
          <submission-workflow-stepper
            v-if="submission"
            :submission="submission"
          />
        </v-menu>
      </div>
    </template>
    <v-card
      flat
      outlined
      rounded="lg"
      style="position: sticky; top: 110px; z-index: 101"
      class="mb-3"
    >
      <v-stepper flat v-model="currentSubmissionStep">
        <v-stepper-header>
          <template v-for="(step, index) in submissionSteps">
            <v-stepper-step :key="step.id" :step="index + 1" color="primary">
              {{ step.text }}</v-stepper-step
            >
            <v-divider
              :key="`${step.id}_divider`"
              v-if="index < submissionSteps.length - 1"
            />
          </template>
        </v-stepper-header>
      </v-stepper>
    </v-card>

    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
      tag="div"
      v-if="submission"
    >
      <v-container class="pt-0 px-0" fluid style="padding-bottom: 80px">
        <v-row v-show="currentSubmissionStepId === 'general-details'">
          <v-col :lg="7">
            <v-card flat :loading="loading" rounded="lg" :outlined="true">
              <v-card-text>
                <submission-form
                  v-model="submission"
                  :read-only-mode="readOnlyMode"
                  @show-order="onShowFullOrder"
                  :additional-documents.sync="additionalDocuments"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :lg="5">
            <v-card flat rounded="lg" outlined>
              <v-card-text class="pa-1">
                <submission-document
                  v-if="submission"
                  :submission="submission"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card
          flat
          rounded="lg"
          v-show="currentSubmissionStepId === 'submission-items'"
        >
          <submission-items
            v-if="submission"
            v-model="submission"
            :vendor-vat="vendorVat"
            :readonly="readOnlyMode"
          />
        </v-card>

        <submission-summary
          v-if="submission && currentSubmissionStepId === 'send'"
          :can-send-submission="!invalid"
          :loading="sending"
          @send="sendSubmission"
        />
      </v-container>
      <v-footer
        fixed
        class="d-flex justify-space-between elevation-4"
        style="column-gap: 10px"
        color="white"
        :height="80"
      >
        <cz-button
          v-if="!decisionMode"
          v-visible="currentSubmissionStepId !== 'send' && !readOnlyMode"
          :title="$t('common.saveDraft')"
          color="primary"
          large
          :disabled="loading || sending || !formChanged"
          :icon-src="mdiContentSave"
          :loading="saving"
          @click="saveSubmission"
        />

        <div class="d-flex" style="column-gap: 15px" v-if="decisionMode">
          <cz-button
            :title="approveButtonTitle"
            dark
            large
            rounded
            color="positive"
            @click="onApprove"
          />
        </div>

        <div class="d-flex justify-center" style="gap: 10px">
          <cz-button
            text
            :title="$t('common.prev')"
            :icon-size="20"
            :icon-src="mdiArrowRight"
            @click="currentSubmissionStep--"
            :disabled="currentSubmissionStep === 1"
            outlined
            :width="100"
            large
          />
          <v-divider vertical class="my-1" />
          <cz-button
            text
            :title="$t('common.next')"
            large
            outlined
            :width="100"
            :icon-src="mdiArrowLeft"
            :disabled="currentSubmissionStep === submissionSteps.length"
            @click="currentSubmissionStep++"
            :icon-size="20"
            icon-location="append"
          />
        </div>
        <div class="d-flex align-center" style="gap: 15px" v-if="decisionMode">
          <cz-button
            :title="
              $t('customer.vendorSubmissions.decisionActionButtons.return')
            "
            dark
            text
            large
            rounded
            color="primary darken-3"
            @click="onReturn"
          />
          <v-divider class="my-2" vertical />
          <cz-button
            :title="
              $t('customer.vendorSubmissions.decisionActionButtons.reject')
            "
            dark
            rounded
            large
            text
            color="negative"
            @click="onReject"
          />
        </div>
        <span v-else style="width: 150px" />
      </v-footer>
    </validation-observer>
    <submission-decision-dialog
      v-if="submissionDecisionDialog.show"
      v-model="submissionDecisionDialog.show"
      v-bind="submissionDecisionDialog"
      @close="submissionDecisionDialog.show = false"
      @on-submit="onSubmitSubmissionDecision"
    />

    <cz-prompt-dialog
      v-if="cancelSubmissionDialog.show"
      v-model="cancelSubmissionDialog.show"
      v-bind="cancelSubmissionDialog"
      @cancel="cancelSubmissionDialog.show = false"
      @done="cancelSubmission"
    />

    <cz-prompt-dialog
      v-if="promptDialog.show"
      v-model="promptDialog.show"
      v-bind="promptDialog"
      hide-cancel-button
      @done="promptDialog.show = false"
    />

    <submission-history-dialog
      v-if="submissionHistoryDialog.show"
      v-model="submissionHistoryDialog.show"
      v-bind="submissionHistoryDialog"
      @close="submissionHistoryDialog.show = false"
    />
  </content-layout>
</template>

<script>
import {
  getOrderSubmission,
  deleteOrderSubmission,
  updateOrderSubmission,
  createOrderSubmissionDecision,
  uploadFiles,
  createOrderSubmissionDocument,
  getOrderSubmissionHistory
} from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { CzButton, CzPromptDialog, CzIcon } from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { mapActions } from 'vuex';
import {
  mdiCheck,
  mdiInformation,
  mdiInformationOutline,
  mdiEye,
  mdiPlus,
  mdiEyeOff,
  mdiDotsHorizontalCircleOutline,
  mdiFileDocumentOutline,
  mdiDelete,
  mdiDeleteOutline,
  mdiSendCheck,
  mdiCloseCircleOutline,
  mdiHistory,
  mdiContentSave,
  mdiContentCopy,
  mdiDownload,
  mdiArrowRight,
  mdiArrowLeft,
  mdiPhoneReturnOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { DecisionType } from '@/domain/customer/components/SubmissionDecisionDialog.vue';
import { typesMixin } from '@/shared/mixins';
import { format, parse } from 'date-fns';
import currencyTypes from '@/shared/types/CurrencyTypes';
import { userHasRoleWithName } from '@/shared/services/rbac/rbac.service';
import RoleType from '@/shared/types/RoleType';
import { BusinessTypeVatAmount } from '@/shared/types/BusinessType';
import { VAT_PERCENT } from '@/shared/constants';

export default {
  name: 'SubmissionPage',
  components: {
    CzPromptDialog,
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    SubmissionDecisionDialog: () =>
      import('@/domain/customer/components/SubmissionDecisionDialog.vue'),
    SubmissionHistoryDialog: () =>
      import('../components/SubmissionHistoryDialog.vue'),
    SubmissionForm: () => import('../components/SubmissionForm.vue'),
    SubmissionItems: () => import('../components/SubmissionItems.vue'),
    SubmissionWorkflowStepper: () =>
      import('../components/SubmissionWorkflowStepper.vue'),
    SubmissionDocument: () => import('../components/SubmissionDocument.vue'),
    SubmissionSummary: () => import('../components/SubmissionSummary.vue'),
    CzButton,
    CzIcon
  },
  props: {
    submissionId: {
      type: String,
      required: true
    },
    predicted: {
      type: Boolean
    }
  },
  mixins: [typesMixin],
  created() {
    this.loadSubmission();
  },
  computed: {
    ...mapFields('vendor', ['vendor']),
    ...mapFields('auth', ['accountId', 'account', 'currentUser']),
    /**
     * Decision mode will be applied only for authorizer/purchaser and with the
     * the relevant status of pending autorizer/purchaser
     */
    decisionMode() {
      return (
        this.userIsApprover &&
        this.submission?.status === OrderSubmissionStatus.PENDING_APPROVAL
      );
    },
    currentVendor() {
      return this.submission?.relatedOrder?.vendor || this.vendor;
    },
    userIsApprover() {
      return this.submission?.approverUserId === this.currentUser?._id;
    },
    readOnlyMode() {
      if (!this.submission) {
        return true;
      }

      // only vendors can edit submissions
      if (!userHasRoleWithName(this.currentUser, RoleType.VENDOR_ADMIN)) {
        return true;
      }

      return [
        OrderSubmissionStatus.PENDING_AUTHORIZER,
        OrderSubmissionStatus.PENDING_PURCHASER,
        OrderSubmissionStatus.PENDING_APPROVAL,
        OrderSubmissionStatus.APPROVED,
        OrderSubmissionStatus.REJECTED
      ].includes(this.submission.status);
    },
    userIsAuthorizer() {
      return userHasRoleWithName(
        this.currentUser,
        RoleType.CUSTOMER_AUTHORIZER
      );
    },

    isVatExempt() {
      return BusinessTypeVatAmount[this.currentVendor?.businessType] === 0;
    },
    vendorVat() {
      const val = BusinessTypeVatAmount[this.currentVendor?.businessType];
      return val !== undefined ? val : VAT_PERCENT;
    },
    userIsPurchaser() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_PURCHASER);
    },
    userIsObserver() {
      return userHasRoleWithName(this.currentUser, RoleType.CUSTOMER_OBSERVER);
    },
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: this.isVendorHomeRoute()
              ? 'vendor-home'
              : this.isCustomerRoute()
              ? 'customer'
              : 'home',
            params: {
              id: this.isVendorHomeRoute() ? this.currentVendor?._id : undefined
            }
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('vendor.orderSubmissions.submissionsTitle'),
          disabled: false,
          exact: true,
          to: {
            name: this.isVendorHomeRoute()
              ? 'vendor-submissions'
              : this.isCustomerRoute()
              ? 'customer-submissions'
              : 'home'
          }
        },
        {
          text: this.$t('vendor.orderSubmissions.submissionTitle', [
            this.submission ? this.submission?.documentNumber : '...'
          ]),
          disabled: true,
          exact: true
        }
      ];
    },
    formChanged() {
      const changed =
        !isEqual(this.submission, this.submissionCopy) ||
        this.additionalDocuments.length > 0;
      return changed;
    },
    orderNumber() {
      return this.submission?.relatedOrder?.orderNumber;
    },
    isManualOrder() {
      return this.submission?.relatedOrder?.creationType === 'manual';
    },
    docName() {
      if (this.documentType === 0) {
        return this.submission?.document?.name;
      } else if (this.documentType === 1) {
        return this.submission.relatedOrder?.orderDocument?.name;
      } else {
        return null;
      }
    },
    approveButtonTitle() {
      return this.$t(
        'customer.vendorSubmissions.decisionActionButtons.approve'
      );
    },
    currentSubmissionStepId() {
      return this.submissionSteps[this.currentSubmissionStep - 1].id;
    },
    submissionSteps() {
      const steps = [];

      steps.push({
        id: 'general-details',
        text: this.$t('vendor.orders.orderSubmissionDetailsForm')
      });

      if (this.isManualOrder) {
        steps.push({
          id: 'submission-items',
          text: this.$t('vendor.orders.submissionItems')
        });
      }

      if (!this.readOnlyMode) {
        steps.push({
          id: 'send',
          text: this.$t('vendor.orders.submissionSummaryAndSend')
        });
      }

      return steps;
    },

    submissionLink() {
      return this.submission?.shortUrl || window.location.href;
    }
  },
  data() {
    return {
      mdiInformationOutline,
      mdiDotsHorizontalCircleOutline,
      mdiCloseCircleOutline,
      mdiArrowRight,
      mdiArrowLeft,
      mdiDownload,
      mdiContentCopy,
      mdiPlus,
      mdiDelete,
      mdiDeleteOutline,
      mdiCheck,
      mdiInformation,
      mdiEye,
      mdiEyeOff,
      mdiSendCheck,
      mdiHistory,
      mdiContentSave,
      mdiFileDocumentOutline,
      currencyTypes,
      documentType: 0,
      tab: 0,
      currentSubmissionStep: 1,
      submission: null,
      submissionCopy: null,
      additionalDocuments: [],
      loading: false,
      sending: false,
      saving: false,
      showDocs: false,
      issueDate: null,
      submissionDecisionDialog: {
        loading: false,
        show: false,
        decisionType: undefined,
        objectType: 'order-submission'
      },
      loadingSubmissionHistory: false,
      cancelSubmissionDialog: {
        show: false,
        title: this.$t('vendor.orderSubmissions.cancelSubmissionTitle'),
        message: this.$t('vendor.orderSubmissions.cancelSubmissionMessage'),
        doneButtonTitle: this.$t(
          'vendor.orderSubmissions.cancelSubmissionActionTitle'
        ),
        loading: false
      },
      promptDialog: {
        show: false,
        title: this.$t('common.unableToSendSubmission'),
        message: '',
        state: 'error',
        doneButtonTitle: this.$t('common.close')
      },
      submissionHistoryDialog: {
        show: false,
        loading: false,
        items: []
      },
      loadRetry: 0
    };
  },
  methods: {
    ...mapActions('ui', [
      'showSuccessToastMessage',
      'showErrorToastMessage',
      'showErrorPrompt'
    ]),
    formatAmountWithCurrency,
    formatDateTimeToDisplay,
    async loadSubmission() {
      try {
        this.loading = true;
        const submission = await getOrderSubmission(this.submissionId, {
          accountId: this.isCustomerRoute() ? undefined : this.accountId,
          $populate: ['workflowSteps']
        });

        if (
          this.predicted &&
          submission?.status !== OrderSubmissionStatus.NEW_PREDICTED &&
          this.loadRetry < 2
        ) {
          this.loadSubmission();
          this.loadRetry++;
          return;
        }

        if (submission.issueDate) {
          submission.issueDate = format(
            new Date(submission.issueDate),
            'yyyy-MM-dd'
          );
        }

        this.submission = submission;
        this.submissionCopy = structuredClone(submission);
      } catch (error) {
        if (error.code === 404) {
          this.showErrorToastMessage(error.message);
          if (this.isVendorHomeRoute) {
            this.$router.replace({
              name: 'vendor-submissions'
            });
          } else if (this.isCustomerRoute) {
            this.$router.replace({
              name: 'customer-submissions'
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    onAdditionalDocumentChange(files, index) {
      this.additionalDocuments[index].file = files[0];
    },
    onAddDocument() {
      // add empty submission document to list
      this.additionalDocuments.push({
        type: '',
        file: null
      });
    },
    onRemoveDocument(index) {
      this.additionalDocuments.splice(index, 1);
    },
    viewDocument(item) {
      window.open(item.file.url, '_blank');
    },
    onCancelSubmission(item) {
      this.cancelSubmissionDialog.title = this.$t(
        'vendor.orderSubmissions.cancelSubmissionTitle',
        [this.submission.documentNumber]
      );
      this.cancelSubmissionDialog.show = true;
    },
    async cancelSubmission() {
      try {
        this.cancelSubmissionDialog.loading = true;

        await deleteOrderSubmission(this.submission._id, {
          accountId: this.accountId
        });

        this.cancelSubmissionDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionCancelledSuccesMessage')
        );
        this.$router.replace({
          name: 'vendor-submissions'
        });
      } finally {
        this.cancelSubmissionDialog.loading = false;
      }
    },
    async saveSubmission() {
      try {
        const _submission = cloneDeep(this.submission);
        this.saving = true;
        const submissionDocuments = await this.createSubmissionDocuments(
          this.additionalDocuments,
          _submission
        );
        const submissionDocumentIds =
          submissionDocuments?.map((item) => item._id) || [];

        delete _submission.additionalDocumentsIds;
        if (_submission.issueDate) {
          _submission.issueDate = parse(
            _submission.issueDate,
            'yyyy-MM-dd',
            new Date()
          );
        }

        const submission = await updateOrderSubmission(this.submission._id, {
          ..._submission,
          $push: { additionalDocumentsIds: submissionDocumentIds },
          note: ''
        });

        if (submission.issueDate) {
          submission.issueDate = format(
            new Date(submission.issueDate),
            'yyyy-MM-dd'
          );
        }

        this.submission = cloneDeep(submission);

        this.submissionCopy = cloneDeep(this.submission);
        this.additionalDocuments = [];

        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionSavedSuccesMessage')
        );
      } catch (error) {
        this.showErrorPrompt(error.message);
      } finally {
        this.saving = false;
      }
    },
    async sendSubmission() {
      try {
        if (!this.validateSubmissionBeforeSave()) {
          return;
        }

        this.sending = true;
        const _submission = cloneDeep(this.submission);
        const submissionDocuments = await this.createSubmissionDocuments(
          this.additionalDocuments,
          _submission
        );
        const submissionDocumentIds =
          submissionDocuments?.map((item) => item._id) || [];

        delete _submission.additionalDocumentsIds;

        if (_submission.issueDate) {
          _submission.issueDate = parse(
            _submission.issueDate,
            'yyyy-MM-dd',
            new Date()
          );
        }
        await updateOrderSubmission(this.submission._id, {
          $push: { additionalDocumentsIds: submissionDocumentIds },
          ..._submission,
          note: '',
          status: 'pending_approval'
        });
        this.showSuccessToastMessage(
          this.$t('vendor.orderSubmissions.submissionSentSuccesMessage')
        );

        this.$router.replace({
          name: 'vendor-submissions'
        });
      } catch (error) {
        this.showErrorPrompt(error.message);
        // this.showErrorToastMessage(error.message);
      } finally {
        this.sending = false;
      }
    },

    validateSubmissionBeforeSave() {
      // check if total amount exceed order amount
      const amountSub =
        parseFloat(this.submission.relatedOrder.amountLeft) -
        parseFloat(this.submission.totalAmount);

      if (amountSub < -1) {
        this.promptDialog.message = this.$t(
          'vendor.orderSubmissions.totalAmountExceed',
          [
            formatAmountWithCurrency(this.submission.totalAmount),
            formatAmountWithCurrency(this.submission.relatedOrder.amountLeft)
          ]
        );
        this.promptDialog.show = true;
        return false;
      }

      let netAndVat =
        parseFloat(this.submission.netAmount) +
        parseFloat(this.submission.vatAmount);
      netAndVat = parseFloat(netAndVat).toFixed(2);

      // validate submission before save
      if (this.isVatExempt && parseFloat(this.submission.vatAmount) > 0) {
        this.promptDialog.message = this.$t('common.vatExempt');
        this.promptDialog.show = true;
        return false;
      }

      if (
        !this.isVatExempt &&
        parseFloat(this.submission.vatAmount) === 0 &&
        parseFloat(this.submission.netAmount) > 0
      ) {
        this.promptDialog.message = this.$t('common.vatCannotBeZero');
        this.promptDialog.show = true;
        this.currentStepIndex = 1;
        return;
      }

      if (this.submission.vatAmount > 0) {
        const netVatSum =
          this.submission.netAmount * parseFloat(this.vendorVat);

        let vatSub = parseFloat(this.submission.vatAmount - netVatSum).toFixed(
          2
        );

        vatSub = parseFloat(vatSub);

        // validate that order vat and total amount are correct
        if (vatSub > 0.01 || vatSub < -0.01) {
          this.promptDialog.message = this.$t('common.invalidCalcVat');
          this.promptDialog.show = true;
          return false;
        }
      }

      const sub =
        parseFloat(this.submission.totalAmount) - parseFloat(netAndVat);

      if (sub > 0.1 || sub < -0.1) {
        this.promptDialog.message = this.$t('common.invalidTotalAmountCalc');
        this.promptDialog.show = true;
        return false;
      }

      return true;
    },
    async createSubmissionDocuments(documents, submission) {
      if (!documents?.length) {
        return null;
      }

      const files = documents.map((item) => item.file);
      const submissionDocumentsFiles = await uploadFiles(files, this.accountId);

      const submissionDocuments = submissionDocumentsFiles.map(
        (file, index) => {
          return {
            submissionId: submission._id,
            accountId: this.accountId,
            fileId: file._id,
            type: documents[index].type
          };
        }
      );

      const submissionDocRes = await createOrderSubmissionDocument(
        submissionDocuments,
        {
          accountId: this.accountId
        }
      );

      return submissionDocRes;
    },
    onApprove() {
      this.submissionDecisionDialog.decisionType = DecisionType.APPROVE;
      this.submissionDecisionDialog.show = true;
    },
    onReject() {
      this.submissionDecisionDialog.decisionType = DecisionType.REJECT;
      this.submissionDecisionDialog.show = true;
    },
    onReturn() {
      this.submissionDecisionDialog.decisionType = DecisionType.RETURN;
      this.submissionDecisionDialog.show = true;
    },
    async onShowHistory() {
      try {
        const query = {
          submissionId: this.submission._id
        };

        if (
          this.userIsAuthorizer ||
          this.userIsPurchaser ||
          this.userIsObserver
        ) {
          query.customerAccountId = this.accountId;
        } else {
          query.accountId = this.accountId;
        }

        this.loadingSubmissionHistory = true;

        const { data } = await getOrderSubmissionHistory(query);

        this.submissionHistoryDialog.items = data;
        this.submissionHistoryDialog.show = true;
      } catch (error) {
      } finally {
        this.loadingSubmissionHistory = false;
      }
    },
    async onSubmitSubmissionDecision(decisionType, form) {
      try {
        this.submissionDecisionDialog.loading = true;

        await createOrderSubmissionDecision({
          orderSubmissionId: this.submission._id,
          accountId: this.accountId,
          decision: this.getSubmissionStatusFromDecisionType(decisionType),
          returnType: decisionType === DecisionType.RETURN ? form.type : '',
          rejectType:
            decisionType === DecisionType.REJECT ? form.type : undefined,
          note: form.note || ''
        });

        this.submissionDecisionDialog.show = false;

        switch (decisionType) {
          case DecisionType.APPROVE:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionApprovedSuccess')
            );
            if (this.userIsPurchaser) {
              this.loadSubmission();
              return;
            } else {
              break;
            }

          case DecisionType.REJECT:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionRejectedSuccess')
            );
            break;
          case DecisionType.RETURN:
            this.showSuccessToastMessage(
              this.$t('customer.vendorSubmissions.submissionReturnedSuccess')
            );
            break;
        }

        this.$router.replace({
          name: 'customer-submissions'
        });
      } finally {
        this.submissionDecisionDialog.loading = false;
      }
    },
    getSubmissionStatusFromDecisionType(decisionType) {
      switch (decisionType) {
        case DecisionType.APPROVE:
          if (
            this.submission.status === OrderSubmissionStatus.PENDING_AUTHORIZER
          ) {
            return OrderSubmissionStatus.PENDING_PURCHASER;
          } else {
            return OrderSubmissionStatus.APPROVED;
          }

        case DecisionType.RETURN:
          return OrderSubmissionStatus.RETURNED;
        case DecisionType.REJECT:
          return OrderSubmissionStatus.REJECTED;
      }
    },
    isCustomerRoute() {
      return this.$route.matched.some(({ name }) => name === 'customer');
    },
    isVendorHomeRoute() {
      return this.$route.matched.some(({ name }) => name === 'vendor-home');
    },
    onGrabLinkClicked() {
      this.showSuccessToastMessage(this.$t('common.grabLinkInfo'));
    },
    onShowFullOrder() {
      const { href } = this.$router.resolve({
        name: 'customer-order',
        params: {
          id: this.submission.relatedOrderId
        }
      });
      window.open(href, '_blank');
    }
  },
  watch: {
    async currentSubmissionStepId(value) {
      this.$refs.observer.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.container-height {
  max-height: calc(100vh - 360px) !important;
}
</style>
